<template>
  <section>
    <form>
      <v-select
        v-model="dataRange"
        :items="dataRanges"
        label="Zakres wyświetlanych danych"
        chips
      />
      <v-autocomplete
        v-model="customer_filter"
        :items="customers"
        label="Klient"
        chips
        multiple
      />
<!--      <v-select-->
<!--        v-model="customer_filter"-->
<!--        :items="customers"-->
<!--        label="Klient"-->
<!--        multiple-->
<!--        chips-->
<!--      />-->
      <v-select
        v-model="sales_person_filter"
        :items="salesAttendants"
        label="Sprzedawca"
        multiple
        chips
      />
      <v-select
        v-model="product_filter"
        :items="salesTypes"
        label="Rodzaj sprzedaży"
        multiple
        chips
      />
      <b-button variant="warning" v-on:click="resetAnalysis">Reset</b-button>
    </form>
    <div class="data-table">
      <div v-for="(yearData, year) in this.tableData" v-bind:key="year">
        <p>{{ year }}</p>
        <span>{{ roundNumbers(yearData.year_total) }}</span>

        <template v-for="(quarterData, quarter) in yearData">
          <div
            v-bind:key="year + '_' + quarter"
            v-if="quarter !== 'year_total'"
            :class="dataRange === 'years' ? 'closed' : ''"
          >
            <p>Kwartał {{ quarter }}</p>
            <span>{{ roundNumbers(quarterData.quarter_total) }}</span>

            <template v-for="(monthData, month) in quarterData">
              <div
                v-bind:key="month"
                v-if="month !== 'quarter_total'"
                :class="dataRange !== 'months' ? 'closed' : ''"
              >
                <p>{{ month }}</p>
                <span>{{ roundNumbers(monthData) }}</span>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="chart">
      <b-button
        v-if="this.dataRange === 'months'"
        variant="primary"
        v-on:click="predictData"
        >Oblicz prognozę</b-button
      >
      <apexchart
        :series="chartSeries"
        :options="chartOptions"
        type="bar"
      ></apexchart>
    </div>
  </section>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import _ from "lodash";
import ARIMAPromise from "arima/async";

export default {
  name: "Analysis",
  data() {
    return {
      predict: false,
      product_filter: "",
      customer_filter: "",
      sales_person_filter: "",
      dataRange: "months",
      dataRanges: [
        {
          text: "Lata",
          value: "years",
        },
        {
          text: "Kwartały",
          value: "quarters",
        },
        {
          text: "Miesiące",
          value: "months",
        },
      ],
      chartSeries: [],
    };
  },
  watch: {
    async dataRange() {
      this.predict = false;
      this.chartSeries = await this.computChartSeries();
    },
    async predict() {
      this.chartSeries = await this.computChartSeries();
    },
    async product_filter() {
      this.predict = false;
      this.chartSeries = await this.computChartSeries();
    },
    async customer_filter() {
      this.predict = false;
      this.chartSeries = await this.computChartSeries();
    },
    async sales_person_filter() {
      this.predict = false;
      this.chartSeries = await this.computChartSeries();
    },
    async business_data() {
      this.predict = false;
      this.chartSeries = await this.computChartSeries();
    }
  },
  methods: {
    resetAnalysis() {
      this.predict = false;
      this.product_filter = "";
      this.customer_filter = "";
      this.sales_person_filter = "";
      this.dataRange = "months";
    },
    roundNumbers(num) {
      return parseFloat(Math.round(num * (10 ^ 2)) / (10 ^ 2)).toFixed(2);
    },
    filterTableData(data) {
      return data.filter((row) => {
        let rowFilters = [];
        if (this.product_filter.length > 0)
          rowFilters.push(() =>
            Object.values(this.product_filter).some((val) =>
              JSON.parse(row.sales_types).includes(val)
            )
          );

        if (this.customer_filter.length > 0)
          rowFilters.push(() =>
            Object.values(this.customer_filter).some(
              (val) => val === row.client_name
            )
          );

        if (this.sales_person_filter.length > 0)
          rowFilters.push(() =>
            Object.values(this.sales_person_filter)
              .map((el) => (el === "brak" ? -1 : el))
              .some((val) => val == row.sales_attendant)
          );

        if (rowFilters.length > 0 && rowFilters.every((f) => f())) return true;

        return (
          this.product_filter.length === 0 &&
          this.customer_filter.length === 0 &&
          this.sales_person_filter.length === 0
        );
      });
    },
    predictData() {
      /**
       * Always predict only for monthly data ( to have biggest data set for timeframe )
       * @type {string}
       */
      this.dataRange = "months";

      this.predict = true;
    },
    async addDataPrediction(data) {
      return ARIMAPromise.then((ARIMA) => {
        const arima = new ARIMA({
          p: 2,
          d: 1,
          q: 2,
          P: 1,
          D: 0,
          Q: 1,
          s: 12,
          verbose: false,
        }).train(data);
        const res = arima.predict(6);

        const [pred] = res; // Also has "errors" prop holding calculation errors

        return [...data, ...pred.map((el) => el.toFixed(2))];
      });
    },
    async computChartSeries() {
      let { data, returnData } = this.getChartSeries;

      if (this.predict) {
        let predictionData = await this.addDataPrediction(data);
        data = [...data, 0, 0, 0, 0, 0, 0];

        returnData.push({
          name: "Prognozowane",
          data: predictionData,
          type: "line",
        });
      }

      return returnData;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Moja firma", route: "analysis" },
    ]);
    this.$store.dispatch("businessDataList");

    this.dataRange = "months";

    const { returnData } = this.getChartSeries;

    this.chartSeries = returnData;
  },
  computed: {
    getChartSeries() {
      let data = [],
        inputData = { ...this.tableData };

      if (this.dataRange === "years")
        data = Object.values(inputData).map((el) =>
          this.roundNumbers(el.year_total)
        );

      if (this.dataRange === "quarters")
        data = Object.values(inputData)
          .map((yearData) => {
            return Object.values(yearData)
              .map((quarter) => {
                if (typeof quarter === "object")
                  return this.roundNumbers(quarter.quarter_total);
                else return 0;
              })
              .filter((el) => el !== 0);
          })
          .flat();

      if (this.dataRange === "months") {
        data = Object.values(inputData)
          .filter((el) => typeof el === "object")
          .map((el) => Object.values(el))
          .flat()
          .filter((el) => typeof el === "object")
          .map((el) => {
            // eslint-disable-next-line no-use-before-define,no-unused-vars
            let { ["quarter_total"]: omitted, ...rest } = el;
            return Object.values(rest);
          })
          .flat()
          .map((el) => this.roundNumbers(el));
      }

      return {
        data,
        returnData: [
          {
            name: "Sprzedaż",
            data,
            type: "column",
          },
        ],
      };
    },
    ...mapGetters(["business_data"]),
    chartOptions() {
      let categories = [];

      if (this.dataRange === "years") categories = Object.keys(this.tableData);

      if (this.dataRange === "quarters")
        categories = Object.keys(this.tableData)
          .map((year) => [
            year + " Q1",
            year + " Q2",
            year + " Q3",
            year + " Q4",
          ])
          .flat();

      if (this.dataRange === "months")
        categories = Object.values(this.tableData)
          .map((yearData) =>
            typeof yearData === "object"
              ? Object.values(yearData).map((quarterData) =>
                  Object.keys(quarterData)
                )
              : []
          )
          .flat(2)
          .filter((el) => el !== "quarter_total");

      if (this.predict)
        Object.keys(new Array(6).fill(1))
          .map((el) => parseInt(el) + 1)
          .map((el) => {
            let now = new Date(categories[categories.length - 1] + "-01");
            let elDate = new Date(
              now.getFullYear(),
              parseInt(now.getMonth()) + el,
              1
            );

            return (
              elDate.getFullYear() + "-" + (parseInt(elDate.getMonth()) + 1)
            );
          })
          .forEach((key) => categories.push(key));

      return {
        xaxis: {
          categories,
          // type: 'datetime'
        },
        yaxis: [
          {
            title: {
              text: "Suma sprzedaży",
            },
          },
        ],
        chart: {
          height: 550,
          // type: 'line',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
          // enabledOnSeries: [1],
          style: {
            colors: ["#333"],
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },
        stroke: {
          width: [0, 2, 5],
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
      };
    },
    salesAttendants() {
      return [
        ...new Set(
          [...this.business_data].map((e) =>
            e.sales_attendant == -1 ? "brak" : e.sales_attendant
          )
        ),
      ];
    },
    customers() {
      return [...new Set([...this.business_data].map((e) => e.client_name))].sort( (a, b) => a.localeCompare(b));
    },
    salesTypes() {
      return [
        ...new Set(
          [...this.business_data].map((e) => JSON.parse(e.sales_types)).flat()
        ),
      ];
    },
    tableData() {
      let grouped = {},
        businessData = this.filterTableData([...this.business_data]);

      /**
       * Change date to Date object and parse sales types object
       * @type {(*&{order_date: *, sales_types: any})[]}
       */
      businessData = businessData.map((row) => {
        return {
          ...row,
          order_date: new Date(row.order_date),
          sales_types: JSON.parse(row.sales_types),
        };
      });

      /**
       * Group sales by month of each year
       * @type {Dictionary<(*&{order_date: *, sales_types: any})[]>}
       */
      let g = _.groupBy(businessData, (row) => {
        return (
          row.order_date.getFullYear() + "-" + (row.order_date.getMonth() + 1)
        );
      });

      /**
       * For each month get a sum of sales
       * @type {{[p: string]: *, [p: number]: *}}
       */
      g = _.mapValues(g, (collection) => {
        return collection.reduce(
          (prev, curr) =>
            (prev += parseFloat(curr.price_total.replace(",", ""))),
          0
        );
      });

      /**
       * Create return object structure with division in to quarters for each year we have data for
       */
      [...new Set(Object.keys(g).map((k) => k.split("-")[0]))].forEach(
        (year) => (grouped[year] = { 1: {}, 2: {}, 3: {}, 4: {} })
      );

      /**
       * Move grouped data for each month-year to the correct sub object of the grouped object
       */
      for (const [key, value] of Object.entries(g)) {
        const keySplit = key.split("-");
        const quarter = Math.ceil(parseInt(keySplit[1]) / 3);
        grouped[parseInt(keySplit[0])][quarter] = {
          ...grouped[parseInt(keySplit[0])][quarter],
          [key]: value,
        };
      }

      /**
       * Calculate total sales for each quarter and year
       */
      for (const year in grouped) {
        for (const quarter in grouped[year]) {
          grouped[year][quarter].quarter_total = Object.values(
            grouped[year][quarter]
          ).reduce((prev, next) => prev + parseFloat(next), 0);
        }
        grouped[year].year_total = Object.values(grouped[year]).reduce(
          (prev, next) => prev + parseFloat(next.quarter_total),
          0
        );
      }

      return grouped;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 250px;
  align-content: start;

  div {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &.closed {
      display: none;
    }

    p {
      font-weight: bold;
    }
  }
}

section {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-column-gap: 20px;

  form {
    grid-column: 1 / span 2;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 160px repeat(3, 1fr) 130px;
    grid-column-gap: 25px;
    align-items: center;
  }
}
</style>
